<!--
 * @Author: yuwenwen
 * @Date: 2022-11-10 11:21:38
 * @LastEditors: Do not edit
 * @LastEditTime: 2023-02-07 15:53:03
 * @FilePath: \nc-funeral-mobile\src\views\policy\details.vue
-->
<template>
  <div class="page-container page-container-100">
    <div class="policy">
      <div class="policy-article-title text-ellipse-2">
        {{policyDetails.advTitle}}
      </div>
      <div class="policy-article-datetime">{{policyDetails.publishDate}}</div>
      <div class="policy-article-content" v-html="policyDetails.advContent"></div>
    </div>
  </div>
</template>
<script>
import {getPolicyDetails} from '@/api/index.js'
export default {
  name: "Policy",
  data() {
    return {
      policyId:'',
      policyDetails:{}
    };
  },
  created() {
    let {id} = this.$route.query;
    this.policyId = id;
    this.getPolicyDetails();
  },
  mounted() {},
  methods: {
   async getPolicyDetails(){
      let [err,res] = await this.$awaitWrap(getPolicyDetails(this.policyId));
      if(err){
        return ;
      }
      this.policyDetails = res.data;
      console.log(res);
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/styles/index.less";

</style>
<style>
.policy-article-content ol{
  list-style: decimal !important;
  padding: 10px !important;
  margin: 10px !important;
}

.policy-article-content ul{
  list-style: disc !important;
  padding: 10px !important;
  margin: 10px !important;
}
/* 解决编辑器写死宽度的情况下，移动端展示超出问题 */
.policy-article-content section{
  width: 100% !important;
  max-width: 100% !important;
}
.policy-article-content table{
  width: 100% !important;
  max-width: 100% !important;
}
</style>